import { Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import React, { Suspense } from 'react';
const Home = React.lazy(() => import('./components/homeComponents/Home'));
const AboutUs = React.lazy(() => import('./components/AboutComponents/AboutUs.jsx'));
const Career = React.lazy(() => import('./components/CareerComponents/Career.jsx'));
const HavellsPanasonicNews = React.lazy(() => import('./components/homeComponents/HavellsPanasonicNews.jsx'));
const StartupAcquiredNews = React.lazy(() => import('./components/homeComponents/StartupAcquiredNews.jsx'));
const PunjabHaryanaNews = React.lazy(() => import('./components/homeComponents/PunjabHaryanaNews.jsx'));
const Will = React.lazy(() => import('./components/PersonalComponents/Will.jsx'));
const LegalNotice = React.lazy(() => import('./components/PersonalComponents/LegalNotice'));
const PropertyVerification = React.lazy(() => import('./components/PersonalComponents/PropertyVerification.jsx'));
const PropertyRegistration = React.lazy(() => import('./components/PersonalComponents/PropertyRegistration.jsx'));
const MarriageRegistration = React.lazy(() => import('./components/PersonalComponents/MarriageRegistration.jsx'));
const Agreements = React.lazy(() => import('./components/PersonalComponents/Agreements.jsx'));
const ServiceAgreements = React.lazy(() => import('./components/PersonalComponents/ServiceAgreements.jsx'));
const PracticeArea = React.lazy(() => import('./components/practiceAreaComponents/PracticeArea.jsx'));
const IntellectualProperty = React.lazy(() => import('./components/practiceAreaComponents/IntellectualProperty.jsx'));
const Trademark = React.lazy(() => import('./components/practiceAreaComponents/Trademark.jsx'));
const Copyright = React.lazy(() => import('./components/practiceAreaComponents/Copyright.jsx'));
const CriminalLitigation = React.lazy(() => import('./components/practiceAreaComponents/CriminalLitigation.jsx'));
const Litigation = React.lazy(() => import('./components/practiceAreaComponents/Litigation.jsx'));
const RealEstate = React.lazy(() => import('./components/practiceAreaComponents/RealEstate.jsx'));
const Taxation = React.lazy(() => import('./components/practiceAreaComponents/Taxation.jsx'));
const ConsumerForum = React.lazy(() => import('./components/practiceAreaComponents/ConsumerForum.jsx'));
const CorporatelawBankruptcyInsolvency = React.lazy(() => import('./components/practiceAreaComponents/CorporatelawBankruptcyInsolvency.jsx'));
const DisputeResolutionArbitration = React.lazy(() => import('./components/practiceAreaComponents/DisputeResolutionArbitration.jsx'));
const EntertainmentMediaBroadcasting = React.lazy(() => import('./components/practiceAreaComponents/EntertainmentMediaBroadcasting.jsx'));
const FamilyMatrimonialDisputeLitigation = React.lazy(() => import('./components/practiceAreaComponents/FamilyMatrimonialDisputeLitigation.jsx'));
const MergerAcquisitions = React.lazy(() => import('./components/practiceAreaComponents/MergerAcquisitions.jsx'));
const WhiteCollarCrimeIPCrime = React.lazy(() => import('./components/practiceAreaComponents/WhiteCollarCrimeIPCrime.jsx'));
const DesignRegistration = React.lazy(() => import('./components/practiceAreaComponents/DesignRegistration.jsx'));
const DesignRegistrationInDelhi = React.lazy(() => import('./components/practiceAreaComponents/insideDesignRegistration/DesignRegistrationInDelhi.jsx'));
const DirectTax = React.lazy(() => import('./components/practiceAreaComponents/InsideTaxationComponents/DirectTax.jsx'));
const IndirectTax = React.lazy(() => import('./components/practiceAreaComponents/InsideTaxationComponents/IndirectTax.jsx'));
const TrademarkRegistrationinIndia = React.lazy(() => import('./components/IPR/TrademarkRegistrationinIndia.jsx'));
const TrademarkRestorationprocessinIndia = React.lazy(() => import('./components/IPR/TrademarkRestorationprocessinIndia.jsx'));
const TrademarkRenewalinIndia = React.lazy(() => import('./components/IPR/TrademarkRenewalinIndia.jsx'));
const TrademarkAssignmentInIndia = React.lazy(() => import('./components/IPR/TrademarkAssignmentInIndia.jsx'));
const TrademarkHearingInIndia = React.lazy(() => import('./components/IPR/TrademarkHearingInIndia.jsx'));
const TrademarkInvestigationInIndia = React.lazy(() => import('./components/IPR/TrademarkInvestigationInIndia.jsx'));
const TrademarkRegistrationInDelhi = React.lazy(() => import('./components/IPR/TrademarkRegistrationInDelhi.jsx'));
const TrademarkLawyersInDelhi = React.lazy(() => import('./components/IPR/TrademarkLawyersInDelhi.jsx'));
const TrademarkLawyersInMumbai = React.lazy(() => import('./components/IPR/TrademarkLawyersInMumbai.jsx'));
const TrademarkLawyersInPune = React.lazy(() => import('./components/IPR/TrademarkLawyersInPune.jsx'));
const TrademarkLawyersInBangalore = React.lazy(() => import('./components/IPR/TrademarkLawyersInBangalore.jsx'));
const TrademarkClasses = React.lazy(() => import('./components/IPR/TrademarkClasses.jsx'));
const TeademarkWatch = React.lazy(() => import('./components/IPR/TeademarkWatch.jsx'));
const CopyrightRegistrationInIndia = React.lazy(() => import('./components/CopyrightComponents/CopyrightRegistrationInIndia.jsx'));
const CopyrightRegistrationforSoundRecording = React.lazy(() => import('./components/CopyrightComponents/CopyrightRegistrationforSoundRecording.jsx'));
const CopyrightComputerSoftware = React.lazy(() => import('./components/CopyrightComponents/CopyrightComputerSoftware.jsx'));
const CopyrightForCinematographFilm = React.lazy(() => import('./components/CopyrightComponents/CopyrightForCinematographFilm.jsx'));
const CopyrightArtisticWorkInIndia = React.lazy(() => import('./components/CopyrightComponents/CopyrightArtisticWorkInIndia.jsx'));
const ReplyCopyrightObjectionProfessionally = React.lazy(() => import('./components/CopyrightComponents/ReplyCopyrightObjectionProfessionally.jsx'));
const CopyrightLawyersDelhi = React.lazy(() => import('./components/CopyrightComponents/CopyrightLawyersDelhi.jsx'));
const CopyrightLawyersMumbai = React.lazy(() => import('./components/CopyrightComponents/CopyrightLawyersMumbai.jsx'));
const CopyrightLawyersPune = React.lazy(() => import('./components/CopyrightComponents/CopyrightLawyersPune.jsx'));
const CopyrightLawyersBangalore = React.lazy(() => import('./components/CopyrightComponents/CopyrightLawyersBangalore.jsx'));
const CopyrightRegistrationDelhi = React.lazy(() => import('./components/CopyrightComponents/CopyrightRegistrationDelhi.jsx'));
const LawFirmsDelhi = React.lazy(() => import('./components/LawFirmsInDelhi/LawFirmsDelhi.jsx'));
const TrademarkRegistrationCompaign = React.lazy(() => import('./components/Service/TrademarkRegistrationCompaign.jsx'));
const PatentRegistration = React.lazy(() => import('./components/PatentComponents/PatentRegistration.jsx'));
const ProvisionalPatent = React.lazy(() => import('./components/PatentComponents/ProvisionalPatent.jsx'));
const NotFoundPage = React.lazy(() => import('./components/NotFoundPage.jsx'));
const Contact = React.lazy(() => import('./components/ContactComponents/Contact.jsx'));
function App() {
  const helmetContext = {};

  return (
    <>
      <Suspense fallback={<div></div>}>
        <HelmetProvider context={helmetContext}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/career' element={<Career />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/havells-panasonic-news' element={<HavellsPanasonicNews />} />
            <Route path='/startup-acquired-news' element={<StartupAcquiredNews />} />
            <Route path='/punjab-haryana-news' element={<PunjabHaryanaNews />} />
            {/* Personal compnents */}
            <Route path='/will' element={<Will />} />
            <Route path='/legal-notice' element={<LegalNotice />} />
            <Route path='/property-verification' element={<PropertyVerification />} />
            <Route path='/property-registration' element={<PropertyRegistration />} />
            <Route path='/marriage-registration' element={<MarriageRegistration />} />
            <Route path='/agreements' element={<Agreements />} />
            <Route path='/service-agreement' element={<ServiceAgreements />} />
            {/* Practice Area */}
            <Route path='/practice-area' element={<PracticeArea />} />
            <Route path='/intellectual-property' element={<IntellectualProperty />} />
            <Route path='/trademark' element={<Trademark />} />
            <Route path='/copyright' element={<Copyright />} />
            <Route path='/criminal-litigation' element={<CriminalLitigation />} />
            <Route path='/litigation' element={<Litigation />} />
            <Route path='/real-estate' element={<RealEstate />} />
            <Route path='/taxation' element={<Taxation />} />
            <Route path='/consumer-forum' element={<ConsumerForum />} />
            <Route path='/corporate-law-bankruptcy-insolvency' element={<CorporatelawBankruptcyInsolvency />} />
            <Route path='/dispute-resolution-arbitration' element={<DisputeResolutionArbitration />} />
            <Route path='/entertainment-media' element={<EntertainmentMediaBroadcasting />} />
            <Route path='/family-matrimonial' element={<FamilyMatrimonialDisputeLitigation />} />
            <Route path='/merger-acquisitions' element={<MergerAcquisitions />} />
            <Route path='/white-collor-crime-and-ip-crime' element={<WhiteCollarCrimeIPCrime />} />
            <Route path='/design-registration' element={<DesignRegistration />} />
            <Route path='/design-registration-in-delhi' element={<DesignRegistrationInDelhi />} />
            {/* Inside Taxation components  */}
            <Route path='/direct-taxation' element={<DirectTax />} />
            <Route path='/indirect-taxation' element={<IndirectTax />} />
            {/* IPR*/}
            {/* trademark */}
            <Route path='/trademark-registration-in-india' element={<TrademarkRegistrationinIndia />} />
            <Route path='/trademark-restoration-process-in-india' element={<TrademarkRestorationprocessinIndia />} />
            <Route path='/trademark-renewal-in-india' element={<TrademarkRenewalinIndia />} />
            <Route path='/trademark-assignment-in-india' element={<TrademarkAssignmentInIndia />} />
            <Route path='/trademark-hearing-in-india' element={<TrademarkHearingInIndia />} />
            <Route path='/trademark-investigation-in-india' element={<TrademarkInvestigationInIndia />} />
            <Route path='/trademark-registration-in-delhi' element={<TrademarkRegistrationInDelhi />} />
            <Route path='/trademark-lawyers-in-delhi' element={<TrademarkLawyersInDelhi />} />
            <Route path='/trademark-lawyers-in-mumbai' element={<TrademarkLawyersInMumbai />} />
            <Route path='/trademark-lawyers-in-pune' element={<TrademarkLawyersInPune />} />
            <Route path='/trademark-lawyers-in-bangalore' element={<TrademarkLawyersInBangalore />} />
            <Route path='/trademark-classes' element={<TrademarkClasses />} />
            <Route path='/trademark-watch' element={<TeademarkWatch />} />
            <Route path='/law-firms-in-delhi' element={<LawFirmsDelhi />} />
            <Route path='/trademark-registration-compaign' element={<TrademarkRegistrationCompaign />} />
            {/* Copyright */}
            <Route path='/copyright-registration-in-india' element={<CopyrightRegistrationInIndia />} />
            <Route path='/copyright-registration-for-sound-recording' element={<CopyrightRegistrationforSoundRecording />} />
            <Route path='/copyright-of-computer-software' element={<CopyrightComputerSoftware />} />
            <Route path='/copyright-cinematograph-film' element={<CopyrightForCinematographFilm />} />
            <Route path='/copyright-of-artistic-work-india' element={<CopyrightArtisticWorkInIndia />} />
            <Route path='/reply-to-copyrights-objection' element={<ReplyCopyrightObjectionProfessionally />} />
            <Route path='/copyright-lawyers-in-delhi' element={<CopyrightLawyersDelhi />} />
            <Route path='/copyright-lawyers-in-mumbai' element={<CopyrightLawyersMumbai />} />
            <Route path='/copyright-lawyers-in-pune' element={<CopyrightLawyersPune />} />
            <Route path='/copyright-lawyers-in-bangalore' element={<CopyrightLawyersBangalore />} />
            <Route path='/copyright-registration-in-delhi' element={<CopyrightRegistrationDelhi />} />
            {/* Patent  */}
            <Route path='/patent-registration' element={<PatentRegistration />} />
            <Route path='/provisional-patent' element={<ProvisionalPatent />} />

            <Route path='/*' element={<NotFoundPage />} />
          </Routes>
        </HelmetProvider>
      </Suspense>
    </>
  );
}
export default App;