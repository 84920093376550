import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
 
);
// const root = ReactDOM.createRoot(document.getElementById('root'));
// ReactDOM.hydrate(
//   <React.StrictMode>
//     <BrowserRouter>
//       <ScrollToTop>
//         <App />
//       </ScrollToTop>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
reportWebVitals();